import getManufactures from '~/composables/endpoints/manufacturer';

export default defineNuxtRouteMiddleware(async (to, from) => {
  const { manufacturerId, category } = to.params;

  const data = await getManufactures(
    categoriesMap[category],
    '200x200',
    manufacturerId as string
  );

  if (data.length > 0) {
    const manufacturerState = useState(
      'manufacturer',
      () => data[0]
    );
    manufacturerState.value = data[0];
  }
});
